import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string()
      .min(2, 'Name is too short!')
      .max(50, 'Name is too long!')
      .required('Name is required!'),
  email: Yup.string()
      .email('Enter a valid email!')
      .required('Email is required!'),
  company: Yup.string()
      .required('Company is required!'),
  phone: Yup.string()
      .required('phone is required!'),
})

export default validationSchema
