import React from 'react'
import { Field, Formik } from 'formik'
import validationSchema from '../components/forms/QuoteForm/validationSchema'
import { quoteFormRequest } from '../http/formRequests'
import { LoadableQuintButton } from '../components/LoadableComponents'
import quoteimg from '../assets/img/quote_img.svg'
import useBreakpoint from '../customHooks/useBreakpoint'
import { navigate } from 'gatsby-link'

const Quote = () => {
  const isMobile = useBreakpoint('sm', false)
  return (
    <div
      className='container' style={{
        minHeight: `calc(100vh - 85px)`,
        marginTop: 85,
        padding: 0
      }}>
      <div className='row'>
        <div className='col' style={isMobile ? { padding: '2.5rem' } : {}}>
          <h1
            style={{
              color: '#030F4B',
              fontSize: '2.25rem',
              fontWeight: 600,
            }} className='font-main'>Get a Quote</h1>
          <Formik
            initialValues={{ name: '', email: '', company: '', phone: '', services: { web: false, app: false, ux: false, platform: false, research: false, other: false } }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              quoteFormRequest(values.name, values.email, values.company, values.phone, values.services)
                .then(() => {
                  // onClose()
                  setSubmitting(false)
                  resetForm()
                  alert("Your response has been submitted, we'll get back to you soon!")// eslint-disable-line
                  navigate('/')
                })
                .catch(error => {
                  console.log('quoteFormRequest error', error)
                  alert('Error: Please Try Again!')                               // eslint-disable-line
                  setSubmitting(false)
                })
            }}
            render={({
              errors,
              touched,
              isSubmitting,
              handleSubmit,
              handleReset,
              values,
            }) => (<form
              name='contact'
              onSubmit={handleSubmit}
              onReset={handleReset}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'stretch'
              }}
            >
              <div style={{ flex: 1 }}>
                <div className='form-group'>
                  <label
                    htmlFor='name' className='font-main' style={{
                      color: '#344054',
                      fontWeight: 600
                    }}>Full Name</label>
                  <Field
                    style={{ fontSize: 16 }}
                    className='form-control font-main'
                    type='text' placeholder='What’s your name?' name='name' id='name' />

                </div>
                <div className='form-group'>
                  <label
                    htmlFor='company' className='font-main' style={{
                      color: '#344054',
                      fontWeight: 600
                    }}>Company</label>
                  <Field
                    style={{ fontSize: 16 }}
                    className='form-control font-main'
                    type='text' placeholder="Your Company's name" name='company' id='company' />

                </div>
                <div className='form-group'>
                  <label
                    htmlFor='email' className='font-main' style={{
                      color: '#344054',
                      fontWeight: 600
                    }}>Email</label>
                  <Field
                    style={{ fontSize: 16 }}
                    className='form-control font-main'
                    type='email' placeholder='example@mail.com' name='email' id='email' />
                  <small
                    className='color-black-transparent' style={{
                      display: 'block',
                      height: '13px'
                    }}>{touched.email && errors.email ? errors.email : ''}</small>
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='phone' className='font-main' style={{
                      color: '#344054',
                      fontWeight: 600
                    }}>Phone Number</label>
                  <Field
                    style={{ fontSize: 16 }}
                    className='form-control font-main'
                    type='tel' placeholder='+1 (555) 000-0000' name='phone' id='phone' />
                  <small
                    className='color-black-transparent' style={{
                      display: 'block',
                      height: '13px'
                    }}>{touched.phone && errors.phone ? errors.phone : ''}</small>
                </div>
                <span
                  className='font-main' style={{
                    color: '#344054',
                    fontWeight: 600
                  }}>Services</span>
                <div className='container'>
                  <div className='row' style={{ width: '100%' }}>
                    <div className='form-check col'>
                      <Field
                        style={{ fontSize: 16 }}
                        className='form-check-input font-main'
                        type='checkbox' name='services.web' id='services_web' />
                      <label className='form-check-label font-main' htmlFor='services_web'>
                        Website design & dev </label>
                    </div>
                    <div className='form-check col'>
                      <Field
                        style={{ fontSize: 16 }}
                        className='form-check-input font-main'
                        type='checkbox' name='services.app' id='services_app' />
                      <label className='form-check-label font-main' htmlFor='services_app'>
                        App development </label>
                    </div>
                  </div>
                  <div className='row' style={{ width: '100%' }}>
                    <div className='form-check col'>
                      <Field
                        style={{ fontSize: 16 }}
                        className='form-check-input font-main'
                        type='checkbox' name='services.ux' id='services_ux' />
                      <label className='form-check-label font-main' htmlFor='services_ux'>
                        UX design </label>
                    </div>
                    <div className='form-check col'>
                      <Field
                        style={{ fontSize: 16 }}
                        className='form-check-input font-main'
                        type='checkbox' name='services.platform' id='services_platform' />
                      <label className='form-check-label font-main' htmlFor='services_platform'>
                        Platform dev </label>
                    </div>
                  </div>
                  <div className='row' style={{ width: '100%' }}>
                    <div className='form-check col'>
                      <Field
                        style={{ fontSize: 16 }}
                        className='form-check-input font-main'
                        type='checkbox' name='services.research' id='services_research' />
                      <label className='form-check-label font-main' htmlFor='services_research'>
                        User research </label>
                    </div>
                    <div className='form-check col'>
                      <Field
                        style={{ fontSize: 16 }}
                        className='form-check-input font-main'
                        type='checkbox' name='services.other' id='services_other' />
                      <label className='form-check-label font-main' htmlFor='services_other'>
                        Other </label>
                    </div>
                  </div>
                </div>
                <div className='field font-main mt-5'>
                  <div
                    className='control d-flex justify-content-center font-supportive'
                    style={{ position: 'relative' }}>
                    <LoadableQuintButton
                      style={{ width: '100%' }}
                      className='font-supportive button-no-outline'
                      rounded='contained'
                      disabled={isSubmitting}
                      background='#25317B'
                      textColor='#FFFFFF' border={false}
                      onClick={handleSubmit}>Get a quote
                    </LoadableQuintButton>
                  </div>
                </div>
              </div>

            </form>)}
          />
        </div>
        {isMobile ? null : <div className='col'>
          <img src={quoteimg} alt='' className='rounded' /></div>}

      </div>
    </div>
  )
}

export default Quote

